import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col, Form, Popover, OverlayTrigger } from "react-bootstrap";
import { Envelope, Telephone, ArrowBarDown, Stack, Laptop, QuestionCircleFill, Link, Front, HddNetworkFill, LightningChargeFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Skills JS
const webSkills = document.getElementsByClassName("skillWeb");
const designSkills = document.getElementsByClassName("skillDesign");
const skillsWebArray = ['HTML', 'CSS', 'Javascript', 'React.js', 'Vue.js', 'Wordpress', 'Apache', 'MySQL', 'Node', 'PHP', 'jQuery', 'Google Analytics', 'Google Optomize', 'Google Search Console'];
const skillsDesignArray = ['Photoshop', 'Illustrator', 'After Effects', 'Dreamweaver', 'Flash', 'GIMP'];
const skillsWeb = skillsWebArray.map((wSkill) =>
  <div key={wSkill} className="skillBox skillWeb">{wSkill}</div>
);
const skillsDesign = skillsDesignArray.map((dSkill) =>
  <div key={dSkill} className="skillBox skillDesign">{dSkill}</div>
);
const popoverArray = [
  {Title: 'Skills', Text: 'Created using React Lists and Keys.'},
  {Title: 'Work', Text: 'Modified with CSS animations.'}
];

const PopoverComponent = React.forwardRef( ({e, ...props}, ref) => {
  return (
    <Popover ref={ref} id="popover-basic" {...props}>
    <Popover.Header className="popoverHeader" as="h3">{popoverArray[e].Title}</Popover.Header>
      <Popover.Body>
        {popoverArray[e].Text}
      </Popover.Body>
    </Popover>
  )
});

class Home extends React.Component {
  
  constructor() {
    super();
    this.state = {
      value:  "2",
    }
  }

  showVal(e) {    
    if (e.target.value < 1) {      
      for (const el of webSkills) {
        el.classList.add("opacity20");
      }
      for (const el of designSkills) {
        el.classList.remove("opacity20");
      }
    } else if (e.target.value > 1) {
      for (const el of webSkills) {
        el.classList.remove("opacity20");
      }   
      for (const el of designSkills) {
        el.classList.add("opacity20");
      }   
    } else {
      for (const el of webSkills) {
        el.classList.remove("opacity20");
      }
      for (const el of designSkills) {
        el.classList.remove("opacity20");
      }
    }
  }

  componentDidMount() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const sm1 = entry.target.querySelector('.smOne');    
    
        if (entry.isIntersecting) {
          sm1.classList.add('right-animation');     
          return; 
        }   
        
        sm1.classList.remove('right-animation');
    
      }); 
    });

    observer.observe(document.querySelector(".smWrapper"));
    observer.observe(document.querySelector(".fsWrapper"));
    observer.observe(document.querySelector(".eliteWrapper"));
  }

  render() {  
    return (
      
      <div>
        <Container fluid className="contactContainer p-3 fixed-top">          
          <Row>
              <Col className="text-white">
                <p className="mb-0"><b>Jaret Lynch</b> — Web Developer               
                <a href="tel:480-209-2438"><Telephone className="text-white headIcons" size={25} /></a>
                <a href="mailto:lynch.jaret@gmail.com"><Envelope className="text-white headIcons" size={25} /></a>
                </p>
            </Col>
            </Row>
        </Container>
        <Container fluid className="introContainer d-flex align-items-center headerFix">       
          <Container>
            <Row>
              <Col lg={8} className="text-white text-left">
                <h1 className="display-1 introTitle">Hi, welcome to<br/>my portoflio!</h1>
                <p className="d-inline-block introText mt-4 fs-4 fw-light">I enjoy building <span className="colorMainAccent">useful</span>, <span className="colorMainAccent">engaging</span>, and <span className="colorMainAccent">creative</span> websites or web related applications.</p>                                       
              </Col>   
              <Col lg={4} className="d-flex align-items-center">
                <a className="d-block text-center mt-4 exploreButton" href="#overview">
                  <p className="text-white fs-2 fw-bold">Explore</p> 
                  <ArrowBarDown className="text-white" size={40} />
                </a>   
              </Col>           
            </Row>
          </Container>         
      </Container>        

      <Container fluid id="overview">
        <Container className="p-4">
          <Row>
            <Col lg={4} className="text-center sectionPadding">
              <Front className="colorMainAccent" size={75} />
              <h2 className="display-5 colorMainAccent fw-bold pt-3">Front-end</h2> 
              <p className="force-left fs-5 pt-4 w-75 mx-auto">I have the most experience in HTML, CSS, and native Javascript or jQuery to build front-end user interfaces. Followed by Vue.js and most recently React.js. This portoflio is built using React.js in efforts to gain skill with it.</p>
            </Col>
            <Col lg={4} className="text-center sectionPadding">
              <HddNetworkFill className="colorAccent2" size={75} />
              <h2 className="display-5 colorAccent2 fw-bold pt-3">Back-end</h2>
              <p className="force-left fs-5 pt-4 w-75 mx-auto">PHP and Node.js are the two back-end languages I am most familiar with. For authentication I have experiemented with PHP hashing as well as implemented JWT with Node.js. </p>
            </Col>
            <Col lg={4} className="text-center sectionPadding">
              <LightningChargeFill className="colorAccent3" size={75} />
              <h2 className="display-5 colorAccent3 fw-bold pt-3">Other</h2>
              <p className="force-left fs-5 pt-4 w-75 mx-auto">I have administrated, and have experience in customizing, multiple CRMs such as Salesforce, Zendesk, Infusionsoft, Hubspot, and more. I am familiar with MySQL and MongoDB but ready to learn more complex database systems.</p>
            </Col>
          </Row>          
        </Container>
      </Container>

      <Container fluid id="skills" className="sectionPadding" >
        <Container className="p-4">
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className="align-items-center d-flex">
                  <h2 className="display-4 text-center colorMainAccent fw-bold d-inline-block">Skills</h2>  
                  <Stack className="colorMainAccent sectionHeadIcons" size={35} />
                </div>
                <div>
                  <OverlayTrigger trigger="click" placement="left" overlay={<PopoverComponent e={0} />}>
                    <QuestionCircleFill className="colorMainAccent float-right infoIcons" size={20} />
                  </OverlayTrigger>
                </div>                 
              </div>
              <div className="d-flex justify-content-between p-0 mt-3">
                <p className="fs-5 fw-normal mb-0">Design</p>
                <p className="fs-5 fw-normal mb-0">Development</p>                
              </div>    
              <Form.Range defaultValue="1" min="0" max="2" onChange={(e) => {this.showVal(e)}}/>          
              <div className="clearfix">
                {skillsWeb}
                {skillsDesign}         
              </div>
            </Col>
          </Row>          
        </Container>
      </Container>

      <Container fluid id="work" className="sectionPadding">
        <Container className="p-4">
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className="align-items-center d-flex">
                  <h2 className="display-4 text-left text-white fw-bold d-inline-block">Work</h2>  
                  <Laptop className="text-white sectionHeadIcons" size={35} />
                </div>
                <div>
                  <OverlayTrigger trigger="click" placement="left" overlay={<PopoverComponent e={1} />}>
                    <QuestionCircleFill className="text-white float-right infoIcons" size={20} />
                  </OverlayTrigger>
                </div>                 
              </div>              
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="p-3">
            <div className="workSectionOne">
              <div className="workOneSectionOne pt-4 pb-4">
                <Row>
                  <Col lg={5} className="d-flex align-items-center">
                    <div>
                      <a href="https://strongmind.com" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}><h2 className="text-white display-5">StrongMind.com <Link className="text-white" size={35} /></h2></a>                   
                      <div className="sectionSlider pt-3">
                        <div className="slide1">
                          <div className="workSkillBox">Wordpress</div>
                          <div className="workSkillBox">JavaScript</div>
                          <div className="workSkillBox">Custom Animations</div>
                          <div className="workSkillBox">CRM Integrations</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="workExamplesWrapper smWrapper">
                      <a href="https://strongmind.com" target="_blank" rel="noreferrer"><img className="smOne workExampleImages" src="/strongmind-home.PNG" alt="StrongMind Example 1" /></a>               
                    </div>
                  </Col>
                </Row>   
              </div>
              <div className="workOneSectionTwo pt-4 pb-4">
                <Row>
                  <Col lg={5} className="d-flex align-items-center">
                    <div>
                      <a href="https://flipswitch.com" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}><h2 className="text-white display-5">FlipSwitch.com <Link className="text-white" size={35} /></h2></a>             
                      <div className="sectionSlider pt-3 w100">
                        <div className="slide1">
                          <div className="workSkillBox">Wordpress</div>
                          <div className="workSkillBox">JavaScript</div>
                          <div className="workSkillBox">Custom Animations</div>
                          <div className="workSkillBox">Custom Designs</div>
                        </div>
                      </div>   
                    </div>                 
                  </Col>
                  <Col lg={7}>
                    <div className="workExamplesWrapper fsWrapper">
                      <a href="https://flipswitch.com" target="_blank" rel="noreferrer"><img className="smOne workExampleImages" src="/flipswitch-home.PNG" alt="FlipSwitch Example 1" /></a>                
                    </div>
                  </Col>
                </Row>                              
              </div>
              <div className="workOneSectionThree pt-4 pb-4">
                <Row>
                  <Col lg={5} className="d-flex align-items-center">
                    <div>
                      <a href="https://eliteacademic.com" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}><h2 className="text-white display-5">EliteAcademic.com <Link className="text-white" size={35} /></h2></a>                      
                      <div className="sectionSlider pt-3">
                        <div className="slide1">
                          <div className="workSkillBox">Wordpress</div>
                          <div className="workSkillBox">JavaScript</div>
                          <div className="workSkillBox">Custom Animations</div>
                          <div className="workSkillBox">Custom Designs</div>
                          <div className="workSkillBox">Zapier Integrations</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="workExamplesWrapper eliteWrapper">
                      <a href="https://eliteacademic.com" target="_blank" rel="noreferrer"><img className="smOne workExampleImages" src="/elite-home.PNG" alt="Elite Example 1" /></a>
                    </div>
                  </Col>
                </Row>                               
              </div>
            </div>          
          </Row>
        </Container>        
      </Container>
      <Container fluid id="contact" className="sectionPadding" >
        <Container className="p-4">
          <Row>
            <Col className="text-center">
              <h2 className="display-4 text-center colorMainAccent fw-bold">Email Me</h2>
              <a href="mailto:lynch.jaret@gmail.com"><Envelope className="colorMainAccent text-center" size={75} /></a> 
            </Col>
          </Row>          
        </Container>
      </Container>
      <Container fluid id="footer" className="pt-3 pb-2" style={{backgroundColor: '#000'}}>
        <Container className="p-1">
          <Row>
            <Col className="text-center">
              <p className="text-white lead fs-6">© 2021 Jaret Lynch. All Rights Reserved.</p>         
            </Col>
          </Row>          
        </Container>
      </Container>
      </div>      
    );
  }
}

ReactDOM.render(
    <Home />,
    document.getElementById("root")
);